import get from 'lodash/get';
import uniqueId from 'lodash/uniqueId';
import uniqBy from 'lodash/uniqBy';
import { createSelector } from 'reselect';

const statusLens = error => get(error, ['response', 'status']);
const statusTextLens = error => get(error, ['response', 'statusText']);
const apiStatusCodeLens = error =>
  get(error, ['parsedResponse', 'apiStatusCode']);
const xRequestIdLens = error => error?.response?.headers?.get('x-request-id');

const uniqueByStatus = createSelector([errors => errors], errors => {
  const errorsWithStatus = errors.map(item =>
    item.status ? item : { ...item, status: undefined }
  );

  return uniqBy(errorsWithStatus, item => item && item.status);
});

const getErrorMessage = error => ({
  id: uniqueId(),
  status: statusLens(error),
  statusText: statusTextLens(error),
  xRequestId: xRequestIdLens(error)
});

export default {
  getErrorMessage,
  uniqueByStatus,
  statusLens,
  apiStatusCodeLens
};
